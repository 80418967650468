import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    applicableDurations: [],
  }
}

export const state = getDefaultState()

export const mutations = {
  UPDATE_APPLICABLE_DURATIONS(state, applicableDurations) {
    state.applicableDurations = applicableDurations
  },
  RESET_APPLICABLE_DURATIONS(state) {
    Object.assign(state, getDefaultState())
  },
}

export const actions = {
  async resetApplicableDurations(store) {
    store.commit('RESET_APPLICABLE_DURATIONS')
  },
  async fetchApplicableDurations(store, collaboratorUuid) {
    try {
      store.commit('RESET_APPLICABLE_DURATIONS')

      const url = `${BASE}/co/api/v2/contractManager/extension.applicableDurations/${collaboratorUuid}`
      const res = await axios.get(url)
      store.commit('UPDATE_APPLICABLE_DURATIONS', res.data)
      return res.data
    } catch (error) {
      throw new Error('API Error occurred in api/applicableDurations.')
    }
  },
}
