import axios from '@utils/axiosInstance'
import sortOptionsByKey from '@utils/sortOptionsByKey'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    site: null,
    sites: [],
    siteTypes: [],
    zones: [],
    teams: JSON.parse(localStorage.getItem('teams'))
      ? JSON.parse(localStorage.getItem('teams'))
      : [],
    teamsDestinationSite: [],
    hrRegions: [],
    patrons: [],
    holidayTypes: [],
    holidays: [],
    ephemeris: [],
    holidayOrEphemeris: null,
    timeZone: {
      name: 'America/Bogota',
    },
    activityStatusesTeams: [],
    team: null,
    teamNameTags: [],
    collaboratorSiteHolidays: [],
    teamCurrentSections: null,
    fictitious: [],
    issues: [],
    newFeatures: [],
  }
}
export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_SITE(state, site) {
    state.site = site
  },
  UPDATE_SITES(state, sites) {
    if (sites.length > 0) {
      sortOptionsByKey(sites, 'name')
    }
    state.sites = sites
  },
  UPDATE_TEAMS(state, teams) {
    localStorage.setItem('teams', JSON.stringify(teams))
    sortOptionsByKey(teams, 'name')
    state.teams = teams
  },
  UPDATE_TEAMS_DESTINATION_SITE(state, teamsDestinationSite) {
    sortOptionsByKey(teamsDestinationSite, 'name')
    state.teamsDestinationSite = teamsDestinationSite
  },
  UPDATE_HR_REGIONS(state, hrRegions) {
    sortOptionsByKey(hrRegions, 'name')
    state.hrRegions = hrRegions
  },
  UPDATE_PATRONS(state, patrons) {
    state.patrons = patrons
  },
  UPDATE_HOLIDAY_TYPES(state, holidayTypes) {
    state.holidayTypes = holidayTypes
  },
  UPDATE_HOLIDAYS(state, holidays) {
    state.holidays = holidays
  },
  UPDATE_EPHEMERIS(state, ephemeris) {
    state.ephemeris = ephemeris
  },
  UPDATE_HOLIDAY_OR_EPHEMERIS(state, holidayOrEphemeris) {
    state.holidayOrEphemeris = holidayOrEphemeris
  },
  RESET_SITE_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  UPDATE_ACTIVITY_STATUSES_TEAMS(state, activityStatusesTeams) {
    state.activityStatusesTeams = activityStatusesTeams
  },
  UPDATE_TEAM(state, team) {
    state.team = team
  },
  UPDATE_TEAM_NAMETAGS(state, teamNameTags) {
    sortOptionsByKey(teamNameTags, 'name')
    state.teamNameTags = teamNameTags
  },
  UPDATE_COLLABORATOR_SITE_HOLIDAYS(state, collaboratorSiteHolidays) {
    state.collaboratorSiteHolidays = collaboratorSiteHolidays
  },
  UPDATE_SITETYPES(state, siteTypes) {
    sortOptionsByKey(siteTypes, 'name')
    state.siteTypes = siteTypes
  },
  UPDATE_ZONES(state, zones) {
    sortOptionsByKey(zones, 'name')
    state.zones = zones
  },
  UPDATE_STREETTYPES(state, streetTypes) {
    sortOptionsByKey(streetTypes, 'name')
    state.streetTypes = streetTypes
  },
  TOGGLE_ACTIVATE_SITE(state, personnelDivision) {
    const site = state.sites.find(
      (site) => site.personnelDivision === personnelDivision,
    )
    site.enabled = !site.enabled
  },
  UPDATE_TEAM_CURRENT_SECTIONS(state, teamCurrentSections) {
    state.teamCurrentSections = teamCurrentSections
  },
  UPDATE_FICTITIOUS(state, fictitious) {
    state.fictitious = fictitious
  },
  UPDATE_ISSUES(state, issues) {
    state.issues = issues
  },
  UPDATE_NEW_FEATURES(state, newFeatures) {
    state.newFeatures = newFeatures
  },
}

export const actions = {
  // SITES
  // GET
  async fetchSiteAction(store, personnelDivision) {
    const url = `${BASE}/co/api/v2/sites/${personnelDivision}`
    try {
      store.commit('UPDATE_SITE', null)
      const res = await axios.get(`${url}`)
      store.commit('UPDATE_SITE', res.data)
      return res.data
    } catch (error) {
      throw new Error(`API Error occurred. ${url} ${error}`)
    }
  },
  // GET
  async fetchSitesForMapAction(store, filters = {}) {
    try {
      store.commit('UPDATE_SITES', [])
      const filtersArr = joinFilters(filters)
      const queryParams =
        filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''

      const res = await axios.get(
        `${BASE}/co/api/v2/sites/maplist${queryParams}`,
      )
      store.commit('UPDATE_SITES', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred. ${BASE}/co/api/v2/sites/maplist ${error}`,
      )
    }
  },
  // TEAMS
  // GET
  async fetchTeamAction(store, teamUuid) {
    store.commit('UPDATE_TEAM', [])
    const queryParams = teamUuid ? `${teamUuid}` : ''
    const url = `${BASE}/co/api/v2/teams/${queryParams}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_TEAM', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // GET
  async fetchTeamsAction(store, personnelDivision) {
    const teamsFromLS = JSON.parse(localStorage.getItem('teams'))
    if (!teamsFromLS) {
      store.commit('UPDATE_TEAMS', [])
      const queryParams = personnelDivision
        ? `?personnelDivision=${personnelDivision}`
        : ''
      const url = `${BASE}/co/api/v2/teams${queryParams}`
      try {
        const res = await axios.get(url)
        store.commit('UPDATE_TEAMS', res.data)
      } catch (e) {
        throw Error(`API Error occurred in ${url}`)
      }
    }
  },
  // GET
  async fetchTeamsActionNoCache(store, personnelDivision) {
    store.commit('UPDATE_TEAMS', [])
    const queryParams = personnelDivision
      ? `?personnelDivision=${personnelDivision}`
      : ''
    const url = `${BASE}/co/api/v2/teams${queryParams}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_TEAMS', res.data)
      return res.data
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // GET TEAMS DESTINATION SITE (REBILLING)
  async fetchTeamsDestinationSiteAction(store, personnelDivision) {
    store.commit('UPDATE_TEAMS_DESTINATION_SITE', [])
    const queryParams = personnelDivision
      ? `?personnelDivision=${personnelDivision}`
      : ''
    const url = `${BASE}/co/api/v2/teams${queryParams}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_TEAMS_DESTINATION_SITE', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // GET TEAM NAME TAGS
  // GET
  async fetchTeamNameTagAction(store) {
    const url = `${BASE}/co/api/v2/teams/nameTag`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_TEAM_NAMETAGS', res.data)
    } catch (e) {
      throw Error(`API Error occurred in ${url}`)
    }
  },
  // GET TEAM PATRON/S
  // GET
  async fetchTeamPatronsAction(store, teamUuid) {
    try {
      store.commit('UPDATE_PATRONS', [])
      const res = await axios.get(`${BASE}/co/api/v2/teams/${teamUuid}/patrons`)
      store.commit('UPDATE_PATRONS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred. ${BASE}/api/v2/teams/${teamUuid}/patrons`,
      )
    }
  },
  // GET TEAM BY UUID
  // GET
  async fetchTeamByUuidAction(store, teamUuid) {
    try {
      store.commit('UPDATE_TEAM', null)
      const res = await axios.get(`${BASE}/co/api/v2/teams/${teamUuid}`)
      store.commit('UPDATE_TEAM', res.data)
    } catch (error) {
      throw new Error(`API Error occurred. ${BASE}/api/v2/teams/${teamUuid}`)
    }
  },
  // GET HR REGIONS
  // GET
  async fetchStreetTypesAction(store) {
    try {
      const res = await axios.get(`${BASE}/api/v2/streetTypes`)
      store.commit('UPDATE_STREETTYPES', res.data)
    } catch (error) {
      throw new Error(`API Error occurred. ${BASE}/api/v2/streetTypes`)
    }
  },
  // GET HR REGIONS
  // GET
  async fetchHrRegionsAction(store) {
    try {
      const res = await axios.get(`${BASE}/api/v2/hrRegions`)
      store.commit('UPDATE_HR_REGIONS', res.data)
    } catch (error) {
      throw new Error(`API Error occurred. ${BASE}/api/v2/hrRegion`)
    }
  },
  // RESPONSIBLE PATRON
  // GET
  async fetchResponsiblePatronAction(store, personnelDivision) {
    try {
      store.commit('UPDATE_PATRONS', [])
      const res = await axios.get(
        `${BASE}/co/api/v2/sites/${personnelDivision}/directors`,
      )
      store.commit('UPDATE_PATRONS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/sites/${personnelDivision}/directors ${error}`,
      )
    }
  },
  // DIRECTOR PATRON
  // GET
  async fetchDirectorPatronAction(store) {
    try {
      store.commit('UPDATE_PATRONS', [])
      const res = await axios.get(
        `${BASE}/co/api/v2/collaborators/countryLeader`,
      )
      store.commit('UPDATE_PATRONS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/collaborators/countryLeader ${error}`,
      )
    }
  },
  // HOLIDAY TYPES
  // GET
  async fetchHolidayTypesAction(store) {
    try {
      const res = await axios.get(`${BASE}/co/api/v2/holidayTypes`)
      store.commit('UPDATE_HOLIDAY_TYPES', res.data)
      return res.data
    } catch (error) {
      throw new Error(`API Error occurred in /co/api/v2/holidayTypes: ${error}`)
    }
  },
  // SITE YEAR HOLIDAYS
  // GET
  async fetchHolidays(store, filters) {
    store.commit('UPDATE_HOLIDAYS', [])
    const { personnelDivision, year } = filters
    const yearToString = year.toString()
    const yearToInteger = parseInt(year)
    if (yearToString.length !== 4 || isNaN(yearToInteger)) {
      throw new Error('Wrong year requested')
    } else {
      try {
        const res = await axios.get(
          `${BASE}/co/api/v2/calendar/holiday?filter[personnelDivision]=${personnelDivision}&filter[year]=${yearToString}`,
        )
        store.commit('UPDATE_HOLIDAYS', res.data)
        return res.data
      } catch (error) {
        throw new Error(
          `API Error occurred in ${BASE}/co/api/v2/calendar/holiday?filter[personnelDivision]=${personnelDivision}&filter[year]=${yearToString}: ${error}`,
        )
      }
    }
  },
  // SITE YEAR EPHEMERIS
  // GET
  async fetchEphemeris(store, filters) {
    store.commit('UPDATE_EPHEMERIS', [])
    const { personnelDivision, year } = filters
    try {
      const res = await axios.get(
        `${BASE}/co/api/v2/calendar/ephemeris?filter[personnelDivision]=${personnelDivision}&filter[year]=${year}`,
      )
      store.commit('UPDATE_EPHEMERIS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/calendar/ephemeris?filter[personnelDivision]=${personnelDivision}&filter[year]=${year}: ${error}`,
      )
    }
  },
  // SITE YEAR HOLIDAY
  // GET
  async fetchHolidayByUuid(store, uuid) {
    try {
      store.commit('UPDATE_HOLIDAY_OR_EPHEMERIS', null)
      const res = await axios.get(`${BASE}/co/api/v2/calendar/holiday/${uuid}`)
      store.commit('UPDATE_HOLIDAY_OR_EPHEMERIS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/calendar/holiday/${uuid}: ${error}`,
      )
    }
  },
  // EPHEMERIS BY UUID
  // GET
  async fetchEphemerisByUuid(store, uuid) {
    try {
      store.commit('UPDATE_HOLIDAY_OR_EPHEMERIS', null)
      const res = await axios.get(
        `${BASE}/co/api/v2/calendar/ephemeris/${uuid}`,
      )
      store.commit('UPDATE_HOLIDAY_OR_EPHEMERIS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred in ${BASE}/co/api/v2/calendar/ephemeris/${uuid}: ${error}`,
      )
    }
  },
  // SITE TYPE
  // GET
  async fetchSiteTypes(store) {
    const url = `${BASE}/co/api/v2/sites/siteTypes`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_SITETYPES', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
  async fetchZones(store) {
    const url = `${BASE}/api/v2/zones`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_ZONES', res.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // SITE HOLIDAYS (FOR COLLABORATOR & RESPONSIBLE VACATIONS VIEW)
  // GET
  async fetchCollaboratorSiteHolidays(store, filters) {
    const { year, personnelDivision } = filters
    const url = `${BASE}/co/api/v2/calendar/siteHolidays?filter[year]=${year}&filter[personnelDivision]=${personnelDivision}`
    try {
      const resp = await axios.get(url)
      store.commit('UPDATE_COLLABORATOR_SITE_HOLIDAYS', resp.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}`)
    }
  },

  // ///////
  // POST
  // //////

  // SITE CREATE
  // POST
  async siteCreateAction(store, payload) {
    try {
      return await axios.post(`${BASE}/co/api/v2/sites/create`, payload)
    } catch (error) {
      throw new Error(
        `Api error occurred in ${BASE}/co/api/v2/sites/create : ${error}`,
      )
    }
  },
  // SITE UPDATE
  // POST
  async siteUpdateAction(store, payload) {
    try {
      return await axios.post(`${BASE}/co/api/v2/sites/update`, payload)
    } catch (error) {
      throw new Error(
        `Api error occurred in ${BASE}/co/api/v2/sites/update : ${error}`,
      )
    }
  },
  // SITE TOGGLE ACTIVATION
  // POST
  async siteToggleActivationAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/sites/toggleActivation`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `Api error occurred in ${BASE}/co/api/v2/sites/toggleActivation : ${error}`,
      )
    }
  },
  // SITE MANAGERS
  // POST
  async siteManagersAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/sites/siteManager/add`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `Api error occurred in ${BASE}/co/api/v2/sites/siteManager/add : ${error}`,
      )
    }
  },
  // HOLIDAY CREATE
  // POST
  async holidayCreateAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/calendar/holiday.create`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `Api error occurred in ${BASE}/co/api/v2/calendar/holiday.create : ${error}`,
      )
    }
  },
  // HOLIDAY UPDATE
  // POST
  async holidayUpdateAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/calendar/holiday.update`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `Api error occurred in ${BASE}/co/api/v2/calendar/holiday.update: ${error}`,
      )
    }
  },
  // HOLIDAY DELETE
  // POST
  async holidayDeleteAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/calendar/holiday.delete`,
        payload,
      )
    } catch (e) {
      throw Error(`API Error occurred at /co/api/v2/calendar/holiday.delete`)
    }
  },
  // EPHEMERIS CREATE
  // POST
  async ephemerisCreateAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/calendar/ephemeris.create`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `Api error occurred in ${BASE}/co/api/v2/calendar/ephemeris.create : ${error}`,
      )
    }
  },
  // EPHEMERIS UPDATE
  // POST
  async ephemerisUpdateAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/calendar/ephemeris.update`,
        payload,
      )
    } catch (error) {
      throw new Error(
        `Api error occurred in ${BASE}/co/api/v2/calendar/ephemeris.update: ${error}`,
      )
    }
  },
  // EPHEMERIS DELETE
  // POST
  async ephemerisDeleteAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/calendar/ephemeris.delete`,
        payload,
      )
    } catch (e) {
      throw Error(`API Error occurred at /co/api/v2/calendar/ephemeris.delete`)
    }
  },
  // TEAMS MANAGEMENT CREATE
  // POST
  async createTeamAction(store, payload) {
    const url = `${BASE}/co/api/v2/team.create`
    try {
      return await axios.post(url, payload)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
  // TEAMS MANAGEMENT UPDATE
  // POST
  async updateTeamAction(store, payload) {
    const url = `${BASE}/co/api/v2/team.update`
    try {
      return await axios.post(url, payload)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },

  // TEAMS MANAGEMENT TOGGLE ACTIVATION STATUS
  // POST
  async toggleStatusTeamAction(store, payload) {
    const url = `${BASE}/co/api/v2/team.toggleStatus`
    try {
      return await axios.post(url, payload)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
  // TEAMS MANAGEMENT LIST
  // GET
  async fetchActivityStatusesTeamAction(store, filters) {
    store.commit('UPDATE_ACTIVITY_STATUSES_TEAMS', [])
    const url = `${BASE}/co/api/v2/teams/activityStatuses`
    try {
      const { personnelDivision } = filters
      const resp = await axios.get(
        `${url}?filter[personnelDivision]=${personnelDivision}`,
      )
      store.commit('UPDATE_ACTIVITY_STATUSES_TEAMS', resp.data)
    } catch (error) {
      throw new Error(`API Error occurred in ${url}: ${error}`)
    }
  },
  // GET TEAM SECTIONS
  // GET
  async fetchTeamCurrentSections(store, teamUuid) {
    try {
      store.commit('UPDATE_PATRONS', [])
      const res = await axios.get(
        `${BASE}/co/api/v2/teams/${teamUuid}/currentSections`,
      )
      store.commit('UPDATE_TEAM_CURRENT_SECTIONS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred. ${BASE}/api/v2/teams/${teamUuid}/currentSections`,
      )
    }
  },
  // GET FICTITIOUS
  // GET
  async fetchFictitiousFiltered(store, personnelDivision) {
    try {
      store.commit('UPDATE_FICTITIOUS', [])
      const res = await axios.get(
        `${BASE}/co/api/v2/sites/${personnelDivision}/fictitious?filter[withoutCallProcessAssignation]=1`,
      )
      store.commit('UPDATE_FICTITIOUS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred. ${BASE}/api/v2/sites/${personnelDivision}/fictitious`,
      )
    }
  },
  // GET FICTITIOUS IN A PROCESS CALLING CDI FD
  // GET
  async fetchFictitiousFilteredInAProcess(store, filters = {}) {
    try {
      store.commit('UPDATE_FICTITIOUS', [])
      const filtersArr = []
      Object.keys(filters).forEach(function (key) {
        if (filters[key]) {
          filtersArr.push(`${key}=${filters[key]}`)
        }
      })
      const queryParams =
        filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
      const res = await axios.get(
        `${BASE}/co/api/v2/sites/${filters.personnelDivision}/fictitious${queryParams}`,
      )
      store.commit('UPDATE_FICTITIOUS', res.data)
    } catch (error) {
      throw new Error(
        `API Error occurred. ${BASE}/api/v2/sites/${filters.personnelDivision}/fictitious`,
      )
    }
  },
  // GET ISSUES LISTING
  // GET
  async fetchIssuesAction(store, filters = {}) {
    try {
      store.commit('UPDATE_ISSUES', [])
      const filtersArr = []
      Object.keys(filters).forEach(function (key) {
        if (filters[key]) {
          filtersArr.push(`${key}=${filters[key]}`)
        }
      })
      const queryParams =
        filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
      const res = await axios.get(`${BASE}/co/api/v2/issues${queryParams}`)
      store.commit('UPDATE_ISSUES', res.data)
    } catch (error) {
      throw new Error(`API Error occurred. ${BASE}/co/api/v2/issues`)
    }
  },
  // GET NEW FEATURES
  // GET
  async fetchNewFeaturesAction(store, filters = {}) {
    try {
      store.commit('UPDATE_NEW_FEATURES', [])
      const filtersArr = []
      Object.keys(filters).forEach(function (key) {
        if (filters[key]) {
          filtersArr.push(`${key}=${filters[key]}`)
        }
      })
      const queryParams =
        filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
      const res = await axios.get(`${BASE}/co/api/v2/newFeatures${queryParams}`)
      store.commit('UPDATE_NEW_FEATURES', res.data)
    } catch (error) {
      throw new Error(`API Error occurred. ${BASE}/co/api/v2/newFeatures`)
    }
  },
}

function joinFilters(filters) {
  const filtersArr = []
  Object.keys(filters).forEach((key) => {
    if (filters[key] !== null || filters[key] !== undefined) {
      filtersArr.push(`filter[${key}]=${filters[key]}`)
    }
  })
  return filtersArr
}
