import axios from '@utils/axiosInstance'

const BASE = process.env.VUE_APP_BACKEND_PUBLIC_URL

const getDefaultState = () => {
  return {
    positionChangeProcess: null,
    positionChangeProcesses: [],
  }
}

export const state = getDefaultState()

export const getters = {}

export const mutations = {
  UPDATE_POSITION_CHANGE_PROCESS(state, positionChangeProcess) {
    state.positionChangeProcess = positionChangeProcess
  },
  RESET_POSITION_CHANGE_PROCESSES(state) {
    state.positionChangeProcesses = []
  },
  UPDATE_POSITION_CHANGE_PROCESSES(state, positionChangeProcesses) {
    state.positionChangeProcesses = positionChangeProcesses
  },
}

export const actions = {
  // CANCEL LEAVE PROCESS
  // POST
  async cancelPositionChangeProcess(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/positionChangeProcess.cancel`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/positionChangeProcess.cancel`,
      )
    }
  },

  // START CREATE COLLABORATOR POSITION CHANGE PROCESS
  // POST
  async createPositionChangeAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/positionChangeProcess.create`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/positionChangeProcess.create`,
      )
    }
  },

  // EDIT CREATE COLLABORATOR POSITION CHANGE PROCESS
  // POST
  async editPositionChangeAction(store, payload) {
    try {
      return await axios.post(
        `${BASE}/co/api/v2/positionChangeProcess.update`,
        payload,
      )
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/positionChangeProcess.update`,
      )
    }
  },

  // FETCH SPECIFIC POSITION CHANGE PROCESS
  // GET
  async fetchPositionChangeAction(store, processUuid) {
    try {
      store.commit('UPDATE_POSITION_CHANGE_PROCESS', null)
      const response = await axios.get(
        `${BASE}/co/api/v2/positionChangeProcesses/${processUuid}`,
      )
      store.commit('UPDATE_POSITION_CHANGE_PROCESS', response.data)
    } catch (e) {
      throw Error(
        `API Error occurred at /co/api/v2/positionChangeProcesses/${processUuid}`,
      )
    }
  },

  // FETCH POSITION CHANGE PROCESSES
  // GET
  async fetchPositionChangeProcesses(store, filters = {}) {
    const filtersArr = []
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtersArr.push(`filter[${key}]=${filters[key]}`)
      }
    })
    const queryString = filtersArr.length > 0 ? '?' + filtersArr.join('&') : ''
    const url = `${BASE}/co/api/v2/positionChangeProcesses${queryString}`
    try {
      const res = await axios.get(url)
      store.commit('UPDATE_POSITION_CHANGE_PROCESSES', res.data)
    } catch (error) {
      throw new Error(`Api error occurred in ${url}`)
    }
  },
}
